<template>
  <div class="my-flex-container-column">
    <el-collapse v-model="activeNames" @change="handleChange" class="collapse-filter">
      <el-collapse-item title="Фильтр" class="incidents-collapse-item" name="p1">
        <template v-slot:title>
          <span>
            <span>Фильтр</span>
            <el-button class="ml-5"
                       plain
                       size="mini"
                       type="default"
                       @click.stop="clearFilters"
                       style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </span>
        </template>
        <el-row class="m-0" :gutter="5">
          <el-col :span="6">
            <span class="mr-2" style="min-width:13rem;">Номер аварии:</span>
            <el-input size="small" v-model="filterModel.numberFilter" clearable
                      id="incidients-filter--number"></el-input>
          </el-col>
          <el-col :span="12">
            <el-row class="m-0">
              <el-col :span="24"><span class="mr-2"
                                       style="min-width:13rem;">Дата и время аварии:</span></el-col>
              <el-col :span="12">
                <el-date-picker v-model="filterModel.startIncidentTimeRange"
                                v-mask="'##.##.#### ##:##'"
                                size="small"
                                style="width: 100%"
                                type="datetime"
                                range-separator="-"
                                placeholder="дд.мм.гггг чч:мм"
                                format="dd.MM.yyyy HH:mm"
                                :picker-options="datePickerOptions">
                </el-date-picker>
              </el-col>
              <el-col :span="1" style="text-align: center">-</el-col>
              <el-col :span="11">
                <el-date-picker v-model="filterModel.endIncidentTimeRange"
                                v-mask="'##.##.#### ##:##'"
                                size="small"
                                style="width: 100%"
                                type="datetime"
                                range-separator="-"
                                placeholder="дд.мм.гггг чч:мм"
                                format="dd.MM.yyyy HH:mm"
                                :default-time="'23:59:00'"
                                :picker-options="datePickerOptions">
                </el-date-picker>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <span style="min-width:13rem;">Корректировка:</span>
            <el-select filterable :disabled="userHasRole('IncidentsDoneView')"
                       clearable v-model="filterModel.showNeedCorrect" size="small">
              <el-option
                v-for="item in needCorrects"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="m-0" :gutter="5">
          <el-col :span="6">
            <span class="mr-2" style="min-width:13rem;">Оборудование/ЛЭП аварии:</span>
            <remote-search-input
              v-model="filterModel.accidentEquipmentFilter"
              searchUrl="/api/AccidentEquipment/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
          <el-col :span="6">
            <span style="min-width:13rem;">Сработавшее УРЗА:</span>
            <remote-search-input
              v-model="filterModel.secondEquipmentPanelFilter"
              searchUrl="/api/secondEquipmentPanels/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
          <el-col :span="6">
            <span class="mr-2" style="min-width:13rem;">ДЦ:</span>
            <remote-search-input
              v-model="filterModel.dcOrganizationFilter"
              searchUrl="/api/organizations/SearchByDC"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
          <el-col :span="6">
            <span style="min-width:13rem;">Энергосистема:</span>
            <!-- <remote-search-input
              v-model="filterModel.eoRegionFilter"
              searchUrl="/api/regions/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
            /> -->
            <el-select multiple filterable clearable size="small"
                       ref="eoRegionFilterSelector"
                       :collapse-tags="filterModel.eoRegionFilter.length > 3"
                       v-model="filterModel.eoRegionFilter"
                       popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.eoRegionFilter = getSelectedIdsByName(operationalZones, $refs.eoRegionFilterSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in operationalZones"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="m-0" :gutter="5">
          <el-col :span="6">
            <span style="min-width:13rem;">Энергообъект:</span>
            <remote-search-input
              v-model="filterModel.energyObjectFilter"
              searchUrl="/api/energyObjects/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
          <el-col :span="6">
            <span style="min-width:13rem;">Владелец объекта электроэнергетики:</span>
            <remote-search-input
              v-model="filterModel.ownerOrganizationsFilter"
              searchUrl="/api/Organizations/SearchByOwner"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
          <el-col :span="6">
            <span style="min-width:13rem;">Фирменное УРЗА:</span>
            <remote-search-input
              v-model="filterModel.productAssetModelsFilter"
              searchUrl="/api/ProductAssetModels/SearchByName"
              :allow-create=false
              :multiple=true
            />
          </el-col>
          <el-col :span="6">
            <span style="min-width:13rem;">Производитель техники РЗА:</span>
            <remote-search-input
              v-model="filterModel.producerOrganizationsFilter"
              searchUrl="/api/Organizations/SearchByProducer"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
        </el-row>

        <el-row class="m-0" :gutter="5">
          <el-col :span="6">
            <span class="mr-2" style="min-width:13rem;">Организационная причина:</span>
            <staff-culpability-select
              multiple filterable
              :collapse-tags="filterModel.staffCulpabilitiesFilter.length > 5"
              clearable v-model="filterModel.staffCulpabilitiesFilter" size="small"
              popper-class="search-select"
            />
          </el-col>

          <el-col :span="6">
            <span style="min-width:13rem;">Техническая причина:</span>

            <technical-reason-select
              multiple filterable
              :collapse-tags="filterModel.technicalReasonsFilter.length > 5"
              clearable v-model="filterModel.technicalReasonsFilter"
              popper-class="search-select"
            />
          </el-col>

          <el-col :span="6">
            <span style="min-width:13rem;">Детализированная причина:</span>
            <el-select multiple filterable clearable size="small"
                       ref="misoperationReasonsSelector"
                       :collapse-tags="filterModel.misoperationReasonsFilter.length > 5"
                       v-model="filterModel.misoperationReasonsFilter"
                       popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.misoperationReasonsFilter = getSelectedValuesByName(misoperationReasons, $refs.misoperationReasonsSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="6">
            <span class="mr-2" style="min-width:13rem;">Категория персонала:</span>
            <staff-category-select multiple filterable
                                   :collapse-tags="filterModel.staffCategoryFilter.length > 5"
                                   clearable v-model="filterModel.staffCategoryFilter" size="small"
                                   popper-class="search-select">

            </staff-category-select>
          </el-col>
        </el-row>

        <el-row class="m-0" :gutter="5">
          <el-col :span="4">
            <span class="mr-2" style="min-width:13rem;">Стандартная функция:</span>
            <el-select multiple filterable clearable size="small"
                       ref="psrTypeSelector"
                       :collapse-tags="filterModel.psrTypeFilter.length > 5"
                       v-model="filterModel.psrTypeFilter"
                       popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.psrTypeFilter = getSelectedValuesByName(secondEquipmentPsrTypes, $refs.psrTypeSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in secondEquipmentPsrTypes"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span style="min-width:13rem;">Исполнение:</span>
            <el-select multiple filterable clearable size="small"
                       ref="executionsSelector"
                       :collapse-tags="filterModel.executionsFilter.length > 5"
                       v-model="filterModel.executionsFilter"
                       popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.executionsFilter = getSelectedValuesByCode(executions, $refs.executionsSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in executions"
                :key="item.id"
                :label="item.code"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span class="mr-2" style="min-width:13rem;">Вид КЗ:</span>
            <el-select multiple filterable clearable size="small"
                       ref="shortCircuitFormSelector"
                       :collapse-tags="filterModel.shortCircuitFormFilter.length > 5"
                       v-model="filterModel.shortCircuitFormFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.shortCircuitFormFilter = getSelectedValuesByCode(shortCircuitForms, $refs.shortCircuitFormSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in shortCircuitForms"
                :key="item.id"
                :label="item.code"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span class="mr-2" style="min-width:13rem;">Оценка:</span>
            <el-select multiple filterable clearable size="small"
                       ref="estimationSelector"
                       :collapse-tags="filterModel.estimationFilter.length > 5"
                       v-model="filterModel.estimationFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.estimationFilter = getSelectedValuesByCode(estimations, $refs.estimationSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in estimations"
                :key="item.id"
                :label="item.code"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span class="mr-2" style="min-width:13rem;">Напряжение:</span>
            <el-select multiple filterable clearable size="small"
                       ref="baseVoltageSelector"
                       :collapse-tags="filterModel.baseVoltageFilter.length > 5"
                       v-model="filterModel.baseVoltageFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.baseVoltageFilter = getSelectedValuesByName(baseVoltages, $refs.baseVoltageSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in baseVoltages"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span style="min-width:13rem;">Дополнительная информация:</span>
            <el-select multiple filterable clearable size="small"
                       ref="cardInformationSelector"
                       :collapse-tags="filterModel.cardInformationFilter.length > 5"
                       v-model="filterModel.cardInformationFilter"
                       popper-class="search-select">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.cardInformationFilter = getSelectedValuesByName(cardInformation, $refs.cardInformationSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in cardInformation"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="m-0 mb-3" :gutter="5">
          <el-col :span="4">
            <span style="min-width:13rem;">Группа учета:</span>
            <el-select multiple filterable clearable size="small"
                       ref="registrationGroupSelector"
                       :collapse-tags="filterModel.registrationGroupFilter.length > 5"
                       v-model="filterModel.registrationGroupFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.registrationGroupFilter = getSelectedValuesByName(registrationGroups, $refs.registrationGroupSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in registrationGroups"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span style="min-width:13rem;">Повреждённые фазы:</span>
            <el-select multiple filterable clearable size="small"
                       ref="faultedPhasesSelector"
                       :collapse-tags="filterModel.faultedPhasesFilter.length > 5"
                       v-model="filterModel.faultedPhasesFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.faultedPhasesFilter = getSelectedValuesByName(faultedPhases, $refs.faultedPhasesSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in faultedPhases"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span class="mr-2" style="min-width:13rem;">Место КЗ:</span>
            <el-select multiple filterable clearable size="small"
                       ref="shortCircuitPlaceSelector"
                       :collapse-tags="filterModel.shortCircuitPlaceFilter.length > 5"
                       v-model="filterModel.shortCircuitPlaceFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.shortCircuitPlaceFilter = getSelectedValuesByCode(shortCircuitPlaces, $refs.shortCircuitPlaceSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in shortCircuitPlaces"
                :key="item.id"
                :label="item.code"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span style="min-width:13rem;">Проверка БММС:</span>
            <el-select multiple filterable clearable size="small"
                       ref="bmmnCheckTypesSelector"
                       :collapse-tags="filterModel.bmmnCheckTypesFilter.length > 5"
                       v-model="filterModel.bmmnCheckTypesFilter">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.bmmnCheckTypesFilter = getSelectedValuesByName(bmmnCheckTypes, $refs.bmmnCheckTypesSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in bmmnCheckTypes"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="4">
            <span style="min-width:13rem;">Сработавший КРЗА:</span>
            <remote-search-input
              v-model="filterModel.secondEquipmentTypesFilter"
              searchUrl="/api/secondEquipmentTypes/SearchByName"
              :allow-create=false
              :multiple=true
              :start-length=3
            />
          </el-col>
          <el-col :span="4">
            <el-row class="m-0">
              <el-col><span class="mr-2" style="min-width:5rem;">Отображать только удаленные</span>
              </el-col>
              <el-col>
                <el-switch size="small" v-model="filterModel.showDeleted"
                           :disabled="unionMode"></el-switch>
              </el-col>
            </el-row>
          </el-col>

        </el-row>
        <!-- <el-row class="m-0 filter">
          <el-col :span="8" class="ml-2 filter--container">
            <el-button @click="clearFilters" type="text"
                       style="font-family: Montserrat-Medium !important;">
              Очистить фильтры
            </el-button>
          </el-col>
        </el-row> -->
      </el-collapse-item>
    </el-collapse>

    <el-row class="m-0" :gutter="20">
      <template v-if="userHasRole('IncidentsEdit')">
        <el-col :span="2">
          <router-link :to="{ name: 'IncidentAdd' }">
            <el-button type="text" icon="el-icon-circle-plus-outline">Добавить</el-button>
          </router-link>
        </el-col>
        <el-col :span="2" v-if="!unionMode">
          <el-button type="text" icon="el-icon-share" :disabled="filterModel.showDeleted"
                     @click="unionModeEnable()">Объединение
          </el-button>
        </el-col>
        <el-col :span="2" v-if="unionMode">
          <el-button type="text" icon="el-icon-circle-check" @click="unionIncidents()">Объединить
          </el-button>
        </el-col>
        <el-col :span="2" v-if="unionMode">
          <el-button type="text" icon="el-icon-circle-close" @click="unionModeCancel()">Отменить
          </el-button>
        </el-col>
      </template>
      <template v-else>
        <el-col :span="4">
          &nbsp;
        </el-col>
      </template>
      <el-col :span="6">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="sortModel.pageSize"
          :current-page.sync="sortModel.pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div class="my-flex-data-content">
      <el-table v-loading="loading"
                :data="incidents"
                height="100%"
                ref="incidentsTable"
                @expand-change="getTree"
                @selection-change="handleSelectionChange"
                style="width: 100%"
                :default-sort="sortModel"
                @sort-change="handleSortChange">

        <el-table-column v-if="unionMode"
                         :key="Math.random()"
                         type="selection"
                         width="55px">
        </el-table-column>

        <el-table-column width="42px" v-if="!unionMode">
          <template slot-scope="props">
            <router-link :to="{ name: 'IncidentItem', params: { id: props.row.id } }">
              <el-button type="text" icon="el-icon-search"></el-button>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column width="42px">
          <template v-if="userHasRole('IncidentsEdit')" slot-scope="scope">
            <el-button icon="el-icon-document-copy" type="text"
                       @click="handleClone(scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-button v-if="!loadingTree[props.row.id]"
                       @click="elTreeCollapse('tree' + props.row.id)" type="text" class="ml-2">
              Свернуть/развернуть всё
            </el-button>
            <el-container class="tree-container" style="overflow-y: hidden;">
              <el-tree :data="props.row.childrens"
                       v-loading="loadingTree[props.row.id]"
                       style="padding-left: 0px;"
                       node-key="id"
                       :ref="'tree' + props.row.id">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <div class="tdWraper">
                    <span>
                      <i v-if="node.level == 1"><FactoryIcon/></i>
                      <i v-if="node.level == 2 && node.data.confirmed == true"><SettingIcon/></i>
                      <i v-if="node.level == 2 && node.data.confirmed == false"><ShieldIcon/></i>
                    </span>
                    <span>{{ node.data.name }}</span>
                  </div>
                </span>
              </el-tree>
            </el-container>
          </template>
        </el-table-column>
        <el-table-column label="Номер аварии" prop="number" width="150" sortable="custom">
          <template slot-scope="props">
            <p>{{ props.row.numberString }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Дата и время аварии" prop="incidentTime" width="200"
                         :formatter="dateTimeRenderer" sortable="custom">
        </el-table-column>
        <el-table-column label="Энергообъекты">
          <template slot-scope="props">
            <p>{{ props.row.energyObjectsString }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Оборудование/ЛЭП аварии">
          <template slot-scope="scope">
            {{ eventEquipment(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="Требует корректировки" prop="needCorrect" width="150"
                         :formatter="boolRenderer" align="center"
                         header-align="center" sortable="custom">
        </el-table-column>
        <el-table-column v-if="filterModel.showDeleted" label="Дата и время удаления"
                         prop="deleteTime" width="200" :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column width="42px">
          <template v-if="userHasRole('IncidentsEdit')" slot-scope="scope">
            <el-button v-if="!scope.row.deleteTime" type="text" icon="el-icon-delete"
                       @click="handleDelete(scope.row)"></el-button>
            <el-button v-else type="text" icon="el-icon-refresh-left"
                       @click="handleRestore(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import debounce from 'lodash.debounce';
import incidentsApi from '@/api/eventsAndIncidents/incidents';
import pagination from '@/components/Ui/Pagination';
import FactoryIcon from '@/assets/icons/factory.svg';
import ShieldIcon from '@/assets/icons/shield.svg';
import SettingIcon from '@/assets/icons/settings.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import filterStorage from '@/utilities/filterStorage';
import UserSettingsService from '@/service/userSettingsService';
import staffCategories from '@/api/nsi/staffCategories';
import misoperationReasons from '@/api/nsi/misoperationReasons';
import cardInformation from '@/api/nsi/cardInformation';
import executions from '@/api/nsi/executions';
import TechnicalReasonSelect from '@/components/Ui/TechnicalReason/TechnicalReasonSelect.vue';
import StaffCulpabilitySelect from '@/components/Ui/StaffCulpability/StaffCulpabilitySelect.vue';
import StaffCategorySelect from '@/components/Ui/StaffCategory/StaffCategorySelect.vue';
import filterHelper from '@/mixins/filterHelper';

// eslint-disable-next-line no-unused-vars


export default {
  name: 'Incidents',
  props: ['id'],
  mixins: [formatters, dateHelpers, filterHelper],
  components: {
    StaffCategorySelect,
    StaffCulpabilitySelect,
    TechnicalReasonSelect,
    pagination,
    FactoryIcon,
    ShieldIcon,
    SettingIcon,
    RemoteSearchInput,
  },

  computed: {
    ...mapGetters('dictionaries',
      ['baseVoltages',
        'energyObjectPsrTypes',
        'estimations',
        'shortCircuitForms',
        'shortCircuitPlaces',
        'secondEquipmentPsrTypes',
        'registrationGroups',
        'faultedPhases',
        'bmmnCheckTypes',
        'operationalZones'
      ]),
    ...mapGetters('identity', ['userHasRole', 'user'])
  },
  watch: {
    // registrationGroups(newValue) {
    //   console.warn('registrationGroups');
    //   this.registrationGroupList = newValue.filter((el) => el.id !== 100 && el.id !== 101);
    // },
    filterModel: {
      handler(newVal) {
        filterStorage.saveFilter('incidents-filter', newVal);
        this.debouncedGetData();
      },
      deep: true
    },
    sortModel: {
      handler(newVal) {
        filterStorage.saveFilter('incidents-sort', newVal);
        this.debouncedGetData();
      },
      deep: true
    },
  },
  data() {
    return {
      loading: false,
      loadingTree: [],
      activeNames: [''],
      incidents: [],
      unionMode: false,
      multipleSelection: [],
      staffCategories: [],
      misoperationReasons: [],
      cardInformation: [],
      executions: [],
      //  registrationGroupList: [],
      value: null,
      totalCount: 0,
      filterActive: true,
      needCorrects: [
        {
          id: null,
          name: 'Выбрать все'
        },
        {
          id: true,
          name: 'Требующие корректировки'
        },
        {
          id: false,
          name: 'Не требующие корректировки'
        },
      ],
      sortModel: filterStorage.getFilterOrDefault('incidents-sort', {
        prop: 'number',
        order: 'descending',
        pageNumber: 1,
        pageSize: 50,
      }),
      filterModel: {
        showDeleted: false,
        numberFilter: '',
        energyObjectFilter: [],
        primaryEquipmentFilter: [],
        accidentEquipmentFilter: [],
        secondEquipmentPanelFilter: [],
        startIncidentTimeRange: '',
        endIncidentTimeRange: '',
        eoRegionFilter: [],
        dcOrganizationFilter: [],
        psrTypeFilter: [],
        estimationFilter: [],
        shortCircuitFormFilter: [],
        shortCircuitPlaceFilter: [],
        baseVoltageFilter: [],
        staffCategoryFilter: [],
        staffCulpabilitiesFilter: [],
        technicalReasonsFilter: [],
        misoperationReasonsFilter: [],
        secondEquipmentTypesFilter: [],
        ownerOrganizationsFilter: [],
        producerOrganizationsFilter: [],
        cardInformationFilter: [],
        executionsFilter: [],
        productAssetModelsFilter: [],
        registrationGroupFilter: [],
        faultedPhasesFilter: [],
        bmmnCheckTypesFilter: [],
        showNeedCorrect: null
      }
    };
  },
  async created() {
    if (UserSettingsService.has('identity_filter_open', this.user.id)) {
      this.activeNames = ['p1'];
    }

    const staffC = await staffCategories.getStaffCategoriesWithPagination(1, 1000000, null, 'name', false);
    if (staffC.data) {
      this.staffCategories = staffC.data.items;
    }

    const misopReasons = await misoperationReasons.getMisoperationReasonsWithPagination(1, 1000000, null, 'name', false);
    if (misopReasons.data) {
      this.misoperationReasons = misopReasons.data.items;
    }
    const cardInfo = await cardInformation.getCardInformationWithPagination(1, 1000000, null, 'name', false);
    if (cardInfo.data) {
      this.cardInformation = cardInfo.data.items;
    }
    const exec = await executions.getExecutionsWithPagination(1, 1000000, null, 'name', false);
    if (exec.data) {
      this.executions = exec.data.items;
    }

    this.debouncedGetData = debounce(this.getData, 500);

    //  this.$nextTick(() => {
    // //   this.tableHeight = this.getTableHeight();
    //    this.customColumnResize();
    //  });
    const filter = filterStorage.getFilter('incidents-filter');
    const sort = filterStorage.getFilter('incidents-sort');
    if (filter) {
      //  this.filterModel = { ...this.filterModel, ...filter };

      Object.keys(this.filterModel)
        .forEach((key) => {
          this.$set(this.filterModel, key, filter[key]);
        });

      if (sort) {
        //   this.sortModel = { ...this.sortModel, ...sort };
        Object.keys(this.sortModel)
          .forEach((key) => {
            this.$set(this.sortModel, key, sort[key]);
          });
      }
    } else {
      await this.getData(this.pageNumber, this.pageSize);
    }

//    this.tableHeight = this.getTableHeight();
  },
  methods: {
    eventEquipment(row) {
      if (row.primaryEquipments && row.primaryEquipments.length && (!row.secondEquipmentPanels || !row.secondEquipmentPanels.length)) {
        return row.primaryEquipments.map((el) => el.value)
          .join('; ');
      }
      if (row.secondEquipmentPanels && row.secondEquipmentPanels.length && (!row.primaryEquipments || !row.primaryEquipments.length)) {
        return row.secondEquipmentPanels.map((el) => el.value)
          .join('; ');
      }
      if (row.primaryEquipments && row.primaryEquipments.length && row.secondEquipmentPanels && row.secondEquipmentPanels.length) {
        return [...row.primaryEquipments, ...row.secondEquipmentPanels].map((el) => el.value)
          .join('; ');
      }
      return '';
    },
    // eslint-disable-next-line no-unused-vars
    async getTree(row, expand) {
      this.loadingTree[row.id] = true;
      await incidentsApi.getEventCardTreeByIncidentId(row.id)
        .then((response) => {
          row.childrens = response.data;
          this.loadingTree[row.id] = false;
        })
        .then(() => {
          //    this.$nextTick(() => {
          // //     this.tableHeight = this.getTableHeight();
          //      this.customColumnResize();
          //    });
        });
    },
    async getData() {
      this.loading = true;
      if (this.filterModel.eoRegionFilter.length > 0 && !this.filterModel.eoRegionFilter.some((value) => filterStorage.isGuid(value))) {
        this.filterModel.eoRegionFilter = [];
      }
      const params = {
        pageNumber: this.sortModel.pageNumber,
        pageSize: this.sortModel.pageSize,
        showDeleted: this.filterModel.showDeleted,
        numberFilter: this.filterModel.numberFilter,
        startIncidentTimeRange: this.filterModel.startIncidentTimeRange ? this.dateSetter(this.filterModel.startIncidentTimeRange) : null,
        endIncidentTimeRange: this.filterModel.endIncidentTimeRange ? this.dateSetter(this.filterModel.endIncidentTimeRange) : null,
        energyObjectFilter: this.filterModel.energyObjectFilter,
        primaryEquipmentFilter: this.filterModel.primaryEquipmentFilter,
        secondEquipmentPanelFilter: this.filterModel.secondEquipmentPanelFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        dcOrganizationFilter: this.filterModel.dcOrganizationFilter,
        psrTypeFilter: this.filterModel.psrTypeFilter,
        estimationFilter: this.filterModel.estimationFilter,
        shortCircuitFormFilter: this.filterModel.shortCircuitFormFilter,
        shortCircuitPlaceFilter: this.filterModel.shortCircuitPlaceFilter,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        showNeedCorrect: this.filterModel.showNeedCorrect,
        staffCategoryFilter: this.filterModel.staffCategoryFilter,
        accidentEquipmentFilter: this.filterModel.accidentEquipmentFilter,
        staffCulpabilitiesFilter: this.filterModel.staffCulpabilitiesFilter,
        technicalReasonsFilter: this.filterModel.technicalReasonsFilter,
        secondEquipmentTypesFilter: this.filterModel.secondEquipmentTypesFilter,
        misoperationReasonsFilter: this.filterModel.misoperationReasonsFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        producerOrganizationsFilter: this.filterModel.producerOrganizationsFilter,
        cardInformationFilter: this.filterModel.cardInformationFilter,
        executionsFilter: this.filterModel.executionsFilter,
        productAssetModelsFilter: this.filterModel.productAssetModelsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        faultedPhasesFilter: this.filterModel.faultedPhasesFilter,
        bmmnCheckTypesFilter: this.filterModel.bmmnCheckTypesFilter,
        sortField: this.sortModel.prop,
        sortDesc: this.sortModel.order === 'descending'
      };

      if (this.userHasRole('IncidentsDoneView')) {
        params.showNeedCorrect = false;
        this.filterModel.showNeedCorrect = false;
      }

      await incidentsApi.getIncidentList(params)
        .then((response) => {
          this.pageNumber = response.data.pageIndex;
          this.totalCount = response.data.totalCount;
          this.incidents = response.data.items;
          this.loading = false;
        });
    },
    async handleSizeChange(event) {
      this.sortModel.pageNumber = 1;
      this.sortModel.pageSize = event;
      await this.getData();
    },
    async handleCurrentChange(event) {
      this.sortModel.pageNumber = event;
      await this.getData();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortModel.order = null;
        this.sortModel.prop = null;
      } else {
        this.sortModel.order = event.order;
        this.sortModel.prop = event.prop;
      }
    },
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        this.filterActive = true;
        UserSettingsService.set('identity_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('identity_filter_open', this.user.id);
        this.filterActive = false;
      }
    },
    clearFilters() {
      this.filterModel = {
        showDeleted: false,
        numberFilter: '',
        energyObjectFilter: [],
        primaryEquipmentFilter: [],
        secondEquipmentPanelFilter: [],
        startIncidentTimeRange: '',
        endIncidentTimeRange: '',
        eoRegionFilter: [],
        dcOrganizationFilter: [],
        psrTypeFilter: [],
        estimationFilter: [],
        shortCircuitFormFilter: [],
        shortCircuitPlaceFilter: [],
        baseVoltageFilter: [],
        staffCategoryFilter: [],
        staffCulpabilitiesFilter: [],
        technicalReasonsFilter: [],
        misoperationReasonsFilter: [],
        secondEquipmentTypesFilter: [],
        ownerOrganizationsFilter: [],
        producerOrganizationsFilter: [],
        cardInformationFilter: [],
        executionsFilter: [],
        productAssetModelsFilter: [],
        registrationGroupFilter: [],
        faultedPhasesFilter: [],
        bmmnCheckTypesFilter: [],
        showNeedCorrect: null,
      };
      if (this.userHasRole('IncidentsDoneView')) {
        this.filterModel.showNeedCorrect = false;
      }
    },
    handleDelete(incident) {
      this.$confirm('Вы действительно хотите удалить запись? При удалении аварии будут также удалены связанные события', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          await incidentsApi.delete(incident.id);
          this.getData();
        })
        .catch(() => {
        });
    },
    handleRestore(incident) {
      this.$confirm('Вы действительно хотите восстановить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(async () => {
          await incidentsApi.restore(incident.id);
          this.getData();
        })
        .catch(() => {
        });
    },
    handleClone(incident) {
      this.$confirm('Необходимо создать копию выбранной аварии?', 'Подтверждение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning',
      })
        .then(async () => {
          const clonedId = await incidentsApi.clone(incident.id);
          if (clonedId.data != null) {
            this.$router.push({
              name: 'IncidentItem',
              params: { id: clonedId.data }
            });
            this.getData();
          }
        })
        .catch(() => {
        });
    },
    unionModeEnable() {
      this.unionMode = true;
      this.$refs.incidentsTable.doLayout();
    },
    unionModeCancel() {
      this.$refs.incidentsTable.clearSelection();
      this.unionMode = false;
      this.$refs.incidentsTable.doLayout();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    unionIncidents() {
      const arr = this.multipleSelection;
      let items = '<br>';
      // eslint-disable-next-line no-unused-vars
      let ids = '';
      for (let a = 0; a < arr.length; a++) {
        items += `${arr[a].number} - `;
        items += `${moment(this.dateGetter(arr[a].incidentTime))
          .format('DD.MM.YYYY HH:mm')} <br>`;
        if (a < arr.length - 1) {
          ids += `${arr[a].id},`;
        } else {
          ids += `${arr[a].id}`;
        }
      }

      if (arr.length > 1) {
        this.$confirm(`Вы действительно хотите объединить следующие аварии? ${items}`, 'Подтвердите действие', {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'info',
          dangerouslyUseHTMLString: true
        })
          .then(async () => {
            await incidentsApi.unionIncidents(ids);
            this.unionMode = false;
            this.getData();
          })
          .catch(() => {
          });
      } else {
        this.$alert('Для объединения необходимо выбрать не менее 2 аварий', 'Ошибка!', {
          confirmButtonText: 'OK',
          type: 'error'
        });
      }
    },

    // "вроде в принципе работающий нормально" selectAllValues(event) и подобные функции _V2 для фильтров остались в нескольких местах.
    // Вместо них функции для фильтров в filterHelper from '@/mixins/filterHelper'
    // getSelectedValues(arr, value, selector, mapper) и часто встречающиеся сокращённые варианты.
    /*
    selectAllValues(event) {
      const allValues = [];

      // временное решение, но вроде в принципе работает нормально
      const options = event.target.parentNode.parentNode.parentNode.querySelectorAll('li:not([style="display: none;"])');
      options.forEach((el) => (allValues.push(el.innerText)));

      return allValues;
    },
    */

    elTreeCollapse(treeRef) {
      // eslint-disable-next-line no-underscore-dangle
      const nodes = this.$refs[treeRef].store._getAllNodes();
      const opened = nodes.some((x) => x.expanded === true);

      if (opened) {
        nodes.forEach((node) => {
          node.collapse();
        });
      } else {
        nodes.forEach((node) => {
          node.expand();
        });
      }
    }
  }
};
</script>

<style scoped>
.tdWraper > span {
  margin: auto 5px;
}

.tdWraper {
  display: flex;
}

.el-tree-node__children {
  padding: 5px 0px;
  height: auto;
}

.el-tree {
  width: 100%;
}

.el-table:before {
  height: 0px;
}

.el-select {
  max-width: initial;
  width: 100%;
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: auto;
}

.incidents-collapse-item :deep(.el-collapse-item__content) {
  padding-bottom: 0 !important;
}
</style>
